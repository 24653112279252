<template>
  <div class="container-prosker background_contrast mt-10">
      <v-row
        class="wide-banner"
        :style="{
          minHeight:'700px',
          backgroundImage: `url(${require('@/assets/images/page-img/Vector_12.png')})`,
          backgroundPosition: 'left bottom'
        }"
      >
        <v-col cols="12" md="6">
          <div class="flex-grown-1 h-100">
            <div class="p-md-5 text-center text-md-left">
              <h1 class="font-weight-bold green-prosk secondary--text">
                {{ $t("faqs") }}
              </h1>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="p-md-5">
            <v-expansion-panels accordion flat class="mb-6 secondary-prosk" focusable v-model="activeTab">
              <v-expansion-panel v-for="(item, i) in faqs" :key="i" class="white border-radius-small mb-2">
                <v-expansion-panel-header
                    class="font-weight-bold "
                    :class="{'bg-green-prosk': i === activeTab,'title_highlight_color': i === activeTab, 'text-white': i === activeTab, 'secondary--text': i !== activeTab}"
                >
                  <template v-slot:actions>
                    <v-icon :color="i === activeTab ? 'white' : 'secondary'">
                      {{  i === activeTab ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                  </template>
                  {{ $t(item.question) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5" :class="i === activeTab && 'title_highlight_color text-white'" :style="{borderBottomLeftRadius:'16px',borderBottomRightRadius:'16px'}">
                  {{ $t(item.answer) }}
                  <router-link v-show="item.link" :to="{ name: item.link }"> {{ $t("signUp") }}</router-link>
                  <span v-show="item.answer2"> {{$t(item.answer2)}}</span>
                  <span v-show="item.answer3"> {{$t(item.answer3)}} </span>
                  <span v-show="item.answer4"> {{$t(item.answer4)}} </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
         </v-col>
      </v-row>
  </div>
</template>

<script>

export default {
   name: 'FaqSectionClient',
   data () {
      return {
        activeTab: null,
        faqs: [
          {
            question: 'whatIsPROSK',
            answer: 'isAPlatformWeSeekContributeToDigitalTransformationOfMYPIMES',
            answer2: 'isAPlatformWeSeekContributeToDigitalTransformationOfMYPIMES2',
            answer3: 'isAPlatformWeSeekContributeToDigitalTransformationOfMYPIMES3',
            answer4: 'isAPlatformWeSeekContributeToDigitalTransformationOfMYPIMES4'
          },
          {
            question: 'howDoIRegisterInPROSKClient',
            answer: 'createYourUsernamePasswordCompletingFormClient',
            link: 'auth1.sign-up1',
            answer2: 'thenCompleteYouProfileClient'
          },
          {
            question: 'canConnectToAProsker',
            answer: 'connectProsker',
            answer2: 'connectProsker2',
            answer3: 'connectProsker3'
          },
          {
            question: 'howDoUsersContactMeClient',
            answer: 'usersRegisteredInPROSKWillSendYouMessageClient'
          },
          {
            question: 'howDoesPROSKNotifyMeClient',
            answer: 'currentlyFromProfileWillHaveNotificationsClient'
          },
          {
            question: 'canIChargeServicesClient',
            answer: 'soonPROSKWillHaveServiceAcceptPaymentsClient'
          },
          {
            question: 'paymentIsSafeClient',
            answer: 'paymentAnswerClient'
          },
          { question: 'canIScheduleClient', answer: 'soonPROSKWillHaveDigitalAgendaClient' },
          {
            question: 'doesPROSKChargeCommissionClient',
            answer: 'noThatDependsHowYouUsePlatformClient'
          },
          {
            question: 'ifIGetJobThroughAmICoveredClient',
            answer: 'youAreResponsibleComplyingObligationsClient'
          },
          {
            question: 'dataIsSafe',
            answer: 'dataIsSafeAnswer',
            answer2: 'dataIsSafeAnswer2'
          }
        ]
      };
   }
};
</script>
<style scoped>
  .wide-banner {
    margin: 0 -36px !important;
    padding: 0 36px;
  }
</style>
